import * as React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Header from './header'
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
    container,
    heading,
    navLinks,
    navLinkItem,
    navLinkText,
    siteTitle
} from './layout.module.css'

const Layout = ({ pageTitle, children }) => {
    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <div>
            <title>{pageTitle} | {data.site.siteMetadata.title}</title>
            <Header />
            <Box p={isMobile ? 0.5 : 3} mt={1}>
                {children}
            </Box>
        </div>
    )
}

export default Layout
