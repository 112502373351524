import * as React from 'react'

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Grid from '@mui/material/Grid';
import EmailIcon from '@mui/icons-material/Email';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import Typography from '@mui/material/Typography';
import Hidden from '@mui/material/Hidden';
import { useState } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby'
import MainVerticalMenu from './mainVerticalMenu.js'
import * as style from './header.module.css'

const Header = () => {
    const [isOpened, setIsOpened] = useState(false);
    function showMainVerticalMenu() {
        setIsOpened(wasOpened => !wasOpened);
    }

    return (
        <Box>
            <AppBar position="static">
                <Toolbar>
                    <Hidden mdUp>
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={showMainVerticalMenu}>
                            <MenuIcon />
                        </IconButton>
                    </Hidden>
                    <Container maxWidth="lg">
                        <Grid container>
                            <Grid container item sm={12} md={4} >
                                <Link to="/" className={style.navLinks}>
                                    <Box display='flex' flexDirection="column" flexGrow={1} p={1}>
                                        <Typography variant="h6">
                                            Miguel Ccantuta León
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            Software engineer, Java Developer, PS4 addict.
                                        </Typography>
                                    </Box>
                                </Link>
                            </Grid>
                            <Grid container item xs={12} sm={4} alignContent="center" alignItems="center" justifyContent="center">
                                <Hidden mdDown="true">
                                    <Link to="/" className={style.navLinks}>
                                        <Box p={2}>
                                            <Typography variant="subtitle1">
                                                About me
                                            </Typography>
                                        </Box>
                                    </Link>
                                    <Link to="/experience" className={style.navLinks}>
                                        <Box p={2}>
                                            <Typography variant="subtitle1">
                                                Experience
                                            </Typography>

                                        </Box>
                                    </Link>
                                    <Link to="/blog" className={style.navLinks}>
                                        <Box p={2}>
                                            <Typography variant="subtitle1">
                                                Blog
                                            </Typography>

                                        </Box>
                                    </Link>
                                </Hidden>
                            </Grid>
                            <Hidden mdDown="true">
                                <Grid container item xs={12} sm={4} alignContent="center" alignItems="center" justifyContent="flex-end">
                                    <Box p={2}>
                                        <LinkedInIcon onClick={() => window.open('https://www.linkedin.com/in/miguel-ccantuta/')} className={style.mouseIconLink} />
                                    </Box>
                                    <Box p={2}>
                                        <GitHubIcon onClick={() => window.open('https://github.com/mccantuta')} className={style.mouseIconLink} />
                                    </Box>
                                    <Box p={2}>
                                        <TwitterIcon onClick={() => window.open('https://twitter.com/mccantuta')} className={style.mouseIconLink} />
                                    </Box>
                                    <Box p={2}>
                                        <EmailIcon onClick={() => window.open('mailto:mccantuta@gmail.com')} className={style.mouseIconLink} />
                                    </Box>
                                </Grid>
                            </Hidden>
                            <Hidden mdUp="true">
                                <Grid container item xs={12} sm={12} alignContent="center" alignItems="center" justifyContent="left">
                                    <Box p={1}>
                                        <LinkedInIcon onClick={() => window.open('https://www.linkedin.com/in/miguel-ccantuta/')} className={style.mouseIconLink} />
                                    </Box>
                                    <Box p={1}>
                                        <GitHubIcon onClick={() => window.open('https://github.com/mccantuta')} className={style.mouseIconLink} />
                                    </Box>
                                    <Box p={1}>
                                        <TwitterIcon onClick={() => window.open('https://twitter.com/mccantuta')} className={style.mouseIconLink} />
                                    </Box>
                                    <Box p={1}>
                                        <EmailIcon onClick={() => window.open('mailto:mccantuta@gmail.com')} className={style.mouseIconLink} />
                                    </Box>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Container>
                </Toolbar>
            </AppBar >
            {isOpened && <MainVerticalMenu showMenu={showMainVerticalMenu} />}
        </Box >
    )
}

export default Header
