import * as React from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InfoIcon from '@mui/icons-material/Info';
import WorkIcon from '@mui/icons-material/Work';
import BookIcon from '@mui/icons-material/Book';

import { Link } from 'gatsby'

import * as style from './mainVerticalMenu.module.css'

function MainVerticalMenu(props) {

    return (
        <Box>
            <Paper>
                <List>
                    <Link to="/" className={style.navLinks}>
                        <ListItem button key="aboutMe">
                            <ListItemIcon><InfoIcon /></ListItemIcon>
                            <ListItemText primary="About me" />
                        </ListItem>
                    </Link>
                    <Divider />
                    <Link to="/experience" className={style.navLinks}>
                        <ListItem button key="experience" >
                            <ListItemIcon><WorkIcon /></ListItemIcon>
                            <ListItemText primary="Experience" />
                        </ListItem>
                    </Link>
                    <Divider />
                    <Link to="/blog" className={style.navLinks}>
                        <ListItem button key="blog">
                            <ListItemIcon><BookIcon /></ListItemIcon>
                            <ListItemText primary="Blog" />
                        </ListItem>
                    </Link>
                </List>
            </Paper>
        </Box>
    );
}
export default MainVerticalMenu;
